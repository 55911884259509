import { AfterViewInit, Component, DoCheck, ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthLayoutComponent } from 'src/app/shared/components/layout/auth-layout/auth-layout.component';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { CacheService } from 'src/app/shared/services/Cache/cache.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { UserService } from 'src/app/shared/services/User/user.service';
import { typeInterface } from 'src/app/Interfaces/company';
import { Cliente } from 'src/app/Interfaces/customer';
import IMask from 'imask';
@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css']
})
export class UserDataComponent implements OnInit, AfterViewInit {
  @ViewChild('inputPhone') inputPhone!: ElementRef;
  @ViewChild('inputName') inputName!: ElementRef;
  @ViewChild('inputBirth') inputBirth!: ElementRef;

  //#region Variaveis de Estilo
    isChecked: boolean = false;
    foundUser = false;
    lengthInputName: any;
    lengthInputPhone = false;
    lengthInputBirth = true;
    editUser = false;
    corretFormatPhoneNumber: any;
    correctFormatBirth: any
  //#endregion

  //#region Variaveis Locais
    private typeParam!: typeInterface;
    private nameCompany: string = '';
    private phoneMask: any;
    private birthMask: any;

    phoneNumberPattern = /^\(\d{2}\)\d{5}\-\d{4}$/;
    user!: Cliente;
    id_customer: string = '';
    name: string = '';
    phoneNumber: string = '';
    birthData: string = '';
    company!: Company;


  //#endregion

  //#region Inicializadores

    constructor(
      private router: Router,
      private apiService: ApiService,
      private cacheService: CacheService,
      private connection: ConnectionService,
      private authLayout: AuthLayoutComponent,
      private userService: UserService,
      private location: Location,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })

        this.userService.editingUser$.subscribe((newvalue) =>{
          this.foundUser = newvalue
        })

        this.connection.typeParam$.subscribe((newType) => {
          this.typeParam = newType
        })

        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
        })

        this.connection.user$.subscribe(
          (data) =>{
            if(data){
              this.user = data
              this.phoneNumber = this.user.phone
              this.name = this.user.name,
              this.birthData = this.user.birthDate

              if(this.phoneMask && this.birthMask)
              {
                setTimeout(() => {
                  this.phoneMask.updateValue();
                  this.birthMask.updateValue();
                  console.log('Mask atualizado:', {phone: this.phoneMask, birth: this.birthMask})
                }, 0);

              }
            }
          }
        )
    }

    ngOnInit(): void {
      this.disablePullToRefresh()

      this.authLayout.checkScreenSize();

      this.validator();

    }

    ngAfterViewInit(): void {

      setTimeout(() => {
        this.phoneMask = IMask(this.inputPhone.nativeElement, {
          mask: '(00)00000-0000',
        });
        this.birthMask = IMask(this.inputBirth.nativeElement, {
          mask: '00/00/0000',
        });
        this.phoneMask.updateValue();
        this.birthMask.updateValue();
        console.log('Mask aplicado:', {phone: this.phoneMask, birth: this.birthMask})
      }, 0);

      
    }

  //#endregion

  //#region Funções de Estilo (Style)

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    validator(){
      if(this.phoneNumber.length === 14){
        this.foundUser = true;
        this.corretFormatPhoneNumber = true;
        this.lengthInputPhone = true;
      }
      if(this.name.length >= 3){
        this.lengthInputName = true;
      }
    }

  //#endregion

  //#region Funções de Lógica (Logic)

    //retorna a pagina anterior
    goback(): void{
      this.location.back()
    }

    //Acionado toda vez que uma letra é digitada no input do nome
    onInputChangeName(event:any)
    {
      const length = event.target.value.length
      if(length >= 3 )
      {
        this.lengthInputName = true;
      }
      else{
        this.lengthInputName = false;
      }

    }

    //Valida se o input está prenchido corretamente
    validateBirthDate() {
      if(this.birthData.length > 0){
        this.correctFormatBirth = this.birthData.length === 10;
        if(this.birthData.length < 10){
          this.lengthInputBirth = false;
        }
        else{
          this.lengthInputBirth = true;
        }
      }
      else{
        this.correctFormatBirth = undefined;
        this.lengthInputBirth = true;
      }

    }

    //#region Phone Number

      //Formata e valida o telefone do usuário
      formatPhoneNumber(phone: string): string {
        if (!phone) return '';
      
        let rawValue = phone.replace(/\D/g, '');
      
        const firstPart = rawValue.substring(0, 2);
        const secondPart = rawValue.substring(2, 7);
        const thirdPart = rawValue.substring(7, 11);
      
        if (rawValue.length <= 2) {
          return `(${firstPart}`;
        } else if (rawValue.length <= 7) {
          return `(${firstPart})${secondPart}`;
        } else {
          return `(${firstPart})${secondPart}-${thirdPart}`;
        }
      }
  
      //Valida se o input está prenchido corretamente
      validatePhoneNumber() {
        if(this.phoneNumber.length > 0){
          this.corretFormatPhoneNumber = this.phoneNumber.length === 14;
  
          if(this.phoneNumber.length < 14){
            this.lengthInputPhone = false;
          }
          else{
            this.lengthInputPhone = true;
  
            this.getUserIsPhone(this.phoneNumber)
          }
        }
        else{
          this.corretFormatPhoneNumber = undefined;
          this.lengthInputPhone = false;
        }
  
      }

      //Busca o usuario pelo seu telefone
      getUserIsPhone(phoneNumber: string){
        let formatted = this.formatPhoneNumber(phoneNumber);

        if(formatted.length === 14){
          this.apiService.getUsuario(formatted).subscribe(
            (data) => {
              this.handleSuccessfulResponse(data)
            },
            (error) => {
              console.log('Usuario não cadastrado');
              this.handleError(error)
            }
          )
        }
      }

    //#endregion


    //#region Confirm
      
      //Assim que confirmado ele monta os objetos necessarios com os valores dos campos
      submitForm(){

        if(this.lengthInputName && this.lengthInputPhone && this.lengthInputBirth){
          let user = this.cacheService.getItemLocal('user_key');
          let userAddress = this.cacheService.getItemLocal('userAddress');
          if(user)
          {
            if(user.name != this.name || user.phone != this.phoneNumber || user.birthDate != this.birthData)
            {
              this.editUser = true;
              if(this.id_customer != user.id_customer){
                this.id_customer = user.id_customer
              }
    
            }
          }
    
          const cliente: Cliente = {
            name: this.name,
            phone: this.phoneNumber,
            id_customer: this.id_customer,
            document: '',
            birthDate: this.birthData
          }
    
          if(this.foundUser == false)
          {
            cliente.phone = this.phoneNumber;
  
            this.apiService.postUsuario(cliente).subscribe(
              (data) => {
                console.log('Usuario cadastrado com sucesso:', data);
    
                const userKey = {
                  phone: this.phoneNumber,
                  id_customer: this.id_customer,
                  name: this.name,
                  birthDate: this.birthData
                }
  
                if(user){
  
                  if(data.response.Customer.id_customer != user.id_customer){
                    this.cacheService.removeItemLocal('userAddress')
                    this.cacheService.setItemLocal('user_key', userKey)
                  }
                }
    
                this.connection.updateUser(data.response.Customer)
                this.cacheService.setItemLocal('user_key', userKey)
    
                if(userAddress)
                {
                  this.cacheService.removeItemLocal('userAddress')
                }
    
                if(this.typeParam.type == 3)
                {
                  this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
                }
                else{
                  this.router.navigate([this.nameCompany, 'order-confirmation']);
                }
    
    
              },
              (error) => {
                console.error('Erro ao cadastrar usuario:', error);
                this.apiService.getUsuario(this.phoneNumber).subscribe(
                  (data) => {
                    this.handleSuccessfulResponse(data)
                  },
                  (error) => {
                    this.handleError(error)
                  }
                )
              }
            );
    
          }
          else{
            cliente.phone = this.phoneNumber;
            this.pathUser();
          }
        }


      }

      //Edita o usuario
      pathUser(){
        if(this.editUser){
          this.apiService.patchUser(this.id_customer, this.name, this.phoneNumber, this.birthData).subscribe(
            (data) =>{

              const userKey = {
                phone: data.response.phone,
                id_customer: data.response.id_customer,
                name: data.response.name,
                birthDate: data.response.birthDate
              }

              this.cacheService.setItemLocal('user_key', userKey)
              this.connection.updateUser(data.response)

              if(this.typeParam.type == 3)
              {
                this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
              }
              else{
                this.router.navigate([this.nameCompany, 'order-confirmation']);
              }
            },
            (error) =>{
              console.log('Erro ao editar usuario:')
            })
        }
        else{
          if(this.typeParam.type == 3)
          {
            this.router.navigate([this.nameCompany, 'order-confirmation-counter']);
          }
          else{
            this.router.navigate([this.nameCompany, 'order-confirmation']);
          }
        }
      } 

      //Função para lidar com a resposta bem-sucedida
      private handleSuccessfulResponse(data: any): void {
        this.name = data.response.customer.name;
        this.birthData = data.response.customer.birthDate
        this.id_customer = data.response.customer.id_customer;

        this.foundUser = true;
        this.lengthInputName = true;
        this.lengthInputPhone = true;
        this.lengthInputBirth = true;
        this.correctFormatBirth = true;
        this.corretFormatPhoneNumber = true;

        const user = this.cacheService.getItemLocal('user_key');

        const userKey = {
          phone: data.response.customer.phone,
          id_customer: data.response.customer.id_customer,
          name: data.response.customer.name,
          birthDate: data.response.customer.birthDate
        }

        if (user) {
          if (this.id_customer !== user.id_customer) {
            this.cacheService.removeItemLocal('userAddress');
            this.cacheService.setItemLocal('user_key', userKey);
          }
        } else {
          this.cacheService.setItemLocal('user_key', userKey);
        }

        this.connection.updateUser(data.response.customer);

        this.inputPhone.nativeElement.blur();
      }

      //Função para lidar com erros
      private handleError(error: any): void {

        this.name = '';
        this.foundUser = false;
        if(this.name.length >= 3)
        {
          this.lengthInputName = true;
        }
        else{
          this.lengthInputName = false;
        }

        this.inputPhone.nativeElement.blur();
        this.inputName.nativeElement.focus();
      }

    //#endregion

  //#endregion

}

