<app-side-bar *ngIf="showSideBar"></app-side-bar>
<app-top-menu></app-top-menu>
<div class="product-detail">
  <!--#region Header-->
    <div class="div-header"*ngIf="alterationTopNavegation">
      <div class="fixed-bar align-items-center d-flex content">
        <div class="navegation-header-button-fixed" (click)="goback()">
          <div style="display: flex; justify-content: center;" >
            <i class="fa fa-chevron-left" style="font-size: 20px; color: var(--primary-color);"></i>
          </div>
        </div>
        <span>{{ produto.name }}</span>
      </div>
    </div>

    <div class="navegation-header" (click)="goback()" *ngIf="!toggleTopNavegation && !alterationTopNavegation">
      <div class="navegation-header-button">
        <div class="icon-goBack" >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
      </div>
    </div>

    <div class="box-navegation" *ngIf="this.toggleTopNavegation">
      <div class="box-navegation__icon" (click)="goback()">
        <i class="fa fa-chevron-left" aria-hidden="true" style="color: #652969;"></i>
      </div>
      <div class="box-navegation__tittle">
        <h3>Detalhes do produto</h3>
      </div>
    </div>
  <!--#endregion-->

  <!--#region Loading-->
    <div class="show-product"*ngIf="!this.requestSucess">
      <div class="image-product" style="width: 100%; height: 350px;">
        <div class="pre-loading"></div>
      </div>
      <div class="info-product">
        <div class="title-product-detail">
          <span style="height: 20px; width: 121px; background-color: #cbd5dc; border-radius: 5px;"><div class="pre-loading"></div></span>
        </div>
        <div class="description-product-detail" style="width: 100%; height: 50px; background-color: #cbd5dc; margin-top: 15px; padding-top: 0px; border-radius: 5px;">
          <div class="pre-loading"></div>
        </div>
      </div>
    </div>
    <div class="product-detail-body" *ngIf="!this.requestSucess">
      <div></div>
      <div class="list-detail-product">
        <div class="box-collapse">
          <div class="box-title">
            <div class="box-title-content">
              <div style="text-align: left;">
                <span style="height: 20px; width: 121px; background-color: #cbd5dc; display: block; border-radius: 5px;"><div class="pre-loading"></div></span>
              </div>
            </div>
          </div>
          <div class="collapse">
            <div style="width: 100%;">
              <div class="collapse-list">
                <div class="selector" style="    gap: 20px;">
                  <div class="selector-body" style="width: 100%; height: 71px; background-color: #cbd5dc; border-radius: 5px;">
                    <div class="pre-loading"></div>
                  </div>
                  <div class="selector-body" style="width: 100%; height: 71px; background-color: #cbd5dc; border-radius: 5px;">
                    <div class="pre-loading"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-collapse">
          <div class="box-title">
            <div class="box-title-content">
              <div style="text-align: left;">
                <span style="height: 20px; width: 121px; background-color: #cbd5dc; display: block; border-radius: 5px;"><div class="pre-loading"></div></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-navegation" *ngIf="!this.requestSucess">
      <div class="bottom-navegation-box">
        <div class="bottom-counter">
          <div style="height: 40px; background-color: #cbd5dc; width: 108px; border-radius: 5px;">
            <div class="pre-loading">
            </div>
          </div>
          <div style="height: 50px; background-color: #cbd5dc;width: 100%; border-radius: 5px;">
            <div class="pre-loading">
            </div>
          </div>
        </div>
      </div>
    </div>
  <!--#endregion-->
  
  <!--#region Product-->
    <ng-container *ngIf="produto && this.requestSucess">
      <div class="show-product">
        <div class="image-product">
          <ng-container *ngIf="produto && produto.image_prod">
            <picture>
              <img class="img-not-zoom" [src]="produto.image_prod" (click)="zoomImageFirst()" alt="Imagem">
              <div class="div-touch" (click)="zoomImageFirst()">
                <i class="fa-regular fa-hand-pointer touch-image" style="color: var(--primary-color);"></i>
              </div>
            </picture>
            <img [src]="produto.image_prod" class="img-zoom"  [class.zoom]="isZoom" alt="Imagem">
          </ng-container>
          <div class="div-back-img close-img" (click)="notZoomImageLast()" [class.active]="isZoom">
            <i class="fa fa-chevron-left"></i>
          </div>
        </div>
        <div class="box-input-info">
          <div class="info-product">
            <div class="title-product-detail">
              <span>{{ produto.name }}</span>
            </div>
            <ng-container *ngIf="this.produto.pizza == 0">
              <div class="price-product">
                <ng-container *ngIf="this.produto.promotion == 1">
                  <span class="price-product-promotion">{{ this.produto.realPrice | currency:'BRL'}}</span>
                  <span>{{ this.produto.price | currency:'BRL'}}</span>
                </ng-container>
                <ng-container *ngIf="this.produto.promotion == 0">
                  <span>{{ this.produto.price | currency:'BRL'}}</span>
                </ng-container>
              </div>
            </ng-container>
            <div class="description-product-detail">
              {{ produto.description }}
            </div>
          </div>
          <div class="div-input" *ngIf="this.produto.categories != undefined">
            <i class="fa fa-search searsh" style="color: var(--primary-color);" aria-hidden="true" type="button"></i>
            <input class="form-control" placeholder="Pesquise o produto" [value]="this.filter" (keyup)="updatefilter($event)"/>
            <i class="fa-solid fa-x cancel-input" style="color: var(--primary-color);" (click)="this.filter = ''; this.counterLengthSub()" *ngIf="this.filter != ''"></i>
          </div>
        </div>

      </div>

      <div class="product-additionals">
        <div></div>
        <div class="list-category" [ngClass]="{'not-observation': this.company.configs.prodObsActive == 0}">

          <!--For das categorias-->
          <ng-container *ngFor="let categorie of this.produto.categories; let i = index">
              <div [ngStyle]="{ 'display': subCategoryEmpty[i] === 0 ? 'none' : 'block' }">
                <div class="box-collapse" [ngClass]="{'disabled': this.limitFlavors && categorie.flavorCategory == 1 || this.limitEdges && categorie.edgeCategory == 1}" #categorie>
                  <!--Header da categoria-->
                  <div class="box-title" #sectionsCategory>
                    <div class="box-title-content">
                      <div style="text-align: left; width: 100%;" (click)="toggleCollapse(i)">
                        <div>
                          <span class="name-Category">{{ categorie.categoryName }}</span>
                          <ng-container *ngIf="categorie.required != 0">
                            <span class="required-Name" *ngIf="!this.readOnlyState">Obrigatório</span>
                          </ng-container>
                        </div>
                        <ng-container>
                          <!--Categoria obrigatória-->
                          <ng-container *ngIf="categorie.required != 0">
    
                            <!--Categoria de Sabor-->
                            <ng-container *ngIf="categorie.flavorCategory == 1">
    
                              <ng-container *ngIf="produto.maxDivision == 1">
                                <span class="division-text">Escolha até 1 item</span>
                              </ng-container>
    
                              <ng-container *ngIf="produto.maxDivision > 1">
                                <span class="division-text">Escolha de 1 até {{ produto.maxDivision }} itens</span>
                              </ng-container>
    
                            </ng-container>
    
                            <!--Categoria de Borda-->
                            <ng-container *ngIf="categorie.edgeCategory == 1">
    
                              <ng-container *ngIf="this.produto.edgeDivision != 0">

                                <ng-container *ngIf="this.produto.edgeDivision == 1">
                                  <span class="division-text">Escolha até 1 item</span>
                                </ng-container>
      
                                <ng-container *ngIf="this.produto.edgeDivision > 1">
                                  <span class="division-text">Escolha de 1 até {{ this.produto.edgeDivision }} itens</span>
                                </ng-container>

                              </ng-container>

                              <ng-container *ngIf="this.produto.edgeDivision == 0">

                                <ng-container *ngIf="categorie.division == 1">
                                  <span class="division-text">Escolha até 1 item</span>
                                </ng-container>
      
                                <ng-container *ngIf="categorie.division > 1">
                                  <span class="division-text">Escolha de 1 até {{ categorie.division }} itens</span>
                                </ng-container>

                              </ng-container>

    
                            </ng-container>


                            <!--Categoria Padrão-->
                            <ng-container *ngIf="categorie.flavorCategory == 0 && categorie.edgeCategory == 0">
    
                              <ng-container *ngIf="categorie.division == 1">
                                <span class="division-text">Escolha até 1 item</span>
                              </ng-container>
    
                              <ng-container *ngIf="categorie.division > 1">
                                <span class="division-text">Escolha de 1 até {{ categorie.division }} itens</span>
                              </ng-container>
    
                            </ng-container>
      
                          </ng-container>

                          <!--Categoria não obrigatória-->
                          <ng-container *ngIf="categorie.required == 0">
    
                            <!--Categoria de Sabor-->
                            <ng-container *ngIf="categorie.flavorCategory == 1">
    
                              <ng-container *ngIf="produto.maxDivision == 1">
                                <span class="division-text">Escolha até 1 item</span>
                              </ng-container>
    
                              <ng-container *ngIf="produto.maxDivision > 1">
                                <span class="division-text">Escolha até {{ produto.maxDivision }} itens</span>
                              </ng-container>
    
                            </ng-container>
    
                            <!--Categoria de borda-->
                            <ng-container *ngIf="categorie.edgeCategory == 1">

                              <ng-container *ngIf="this.produto.edgeDivision != 0">

                                <ng-container *ngIf="this.produto.edgeDivision == 1">
                                  <span class="division-text">Escolha até 1 item</span>
                                </ng-container>
      
                                <ng-container *ngIf="this.produto.edgeDivision > 1">
                                  <span class="division-text">Escolha até {{ this.produto.edgeDivision }} itens</span>
                                </ng-container>

                              </ng-container>

                              <ng-container *ngIf="this.produto.edgeDivision == 0">

                                <ng-container *ngIf="categorie.division == 1">
                                  <span class="division-text">Escolha até 1 item</span>
                                </ng-container>
      
                                <ng-container *ngIf="categorie.division > 1">
                                  <span class="division-text">Escolha até {{ categorie.division }} itens</span>
                                </ng-container>

                              </ng-container>
    
                            </ng-container>

                            <!--Categoria Padrão-->
                            <ng-container *ngIf="categorie.flavorCategory == 0 && categorie.edgeCategory == 0">
    
                              <ng-container *ngIf="categorie.division == 1">
                                <span class="division-text">Escolha até 1 item</span>
                              </ng-container>
    
                              <ng-container *ngIf="categorie.division > 1">
                                <span class="division-text">Escolha até {{ categorie.division }} itens</span>
                              </ng-container>
    
                            </ng-container>
    
                          </ng-container>
                        </ng-container>
                      </div>
                      <div style="display: flex; align-items: center;" (click)="toggleCollapse(i)">
                        <i class="fa-solid fa-angle-down arrows" [ngStyle]="{'transform':collapseStates[i] ? 'rotate(-90deg)' : 'rotate(0deg)' }"></i>
                      </div>
                    </div>
                  </div>
                  <!--Body da Categoria-->
                  <div class="collapse" [ngClass]="{'close': collapseStates[i]}">
                    <div style="width: 100%;">
                      <div class="collapse-list" #collapseList>
                        <ng-container *ngFor="let adicional of categorie.products; let indexAdd = index">
                          <!-- <ng-container *ngIf="this.teste.add_id_product != adicional.id_product"> -->
                            <ng-container *ngIf="adicional.name.toLowerCase().includes(this.filter) || this.filter == ''">
                              <div class="selector" [ngClass]="{'not-disabled': adicional.quantity > 0}">
                                <div class="selector-body">
                                  <div class="parte-1">
                                    <ng-container *ngIf="adicional.image_prod != 'https://grhxbbpopmfd.objectstorage.sa-saopaulo-1.oci.customer-oci.com/n/grhxbbpopmfd/b/bucket-pedeaqui/o/produto-sem-foto.jpg'">
                                      <picture *ngIf="adicional.image_prod" style="margin-right: 12px;">
                                        <img [src]="adicional.image_prod">
                                      </picture>
                                    </ng-container>
                                    <div class="seletor-info-detail">
                                      <span>{{ adicional.name }}</span>
                                      <p>{{ adicional.description }}</p>
                                      <div>
                                        <div class="selector-price" *ngIf="adicional.price != 0">
                                          <ng-container *ngIf="adicional.promotion == 1">
                                            <span class="selector-price-promotion">{{ adicional.realPrice | currency:'BRL'  }}</span>
                                            <span>{{ adicional.price | currency:'BRL'  }}</span>
                                          </ng-container>
                                          <ng-container *ngIf="adicional.promotion == 0">
                                            <span>{{ adicional.price | currency:'BRL'  }}</span>
                                          </ng-container>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="parte-2" *ngIf="!this.readOnlyState">
                                    <div class="counter" >
                                      <button class="button-down" [ngClass]="{'activated': adicional.quantity > 0}">
                                        <div class="btn-negative">
                                          <div (click)="removeOneInCounterAdd(adicional, categorie, i)">-</div>
                                        </div>
                                      </button>
                                      <div class="counter-div-add">{{ adicional.quantity }}</div>
                                      <button class="button-plus">
                                        <div class="btn-positive">
                                          <div (click)="addOneInCounterAdd(adicional, categorie, i)">+</div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>

                                  <!-- <div *ngIf="!this.readOnlyState && categorie.division > 0">
                                    <div class="check-radio">
                                      <input class="input-type-radio" type="checkbox" [checked]="adicional.quantity == 1" (click)="changeAdd(adicional, categorie, i)" [id]="i +'-radio-' + indexAdd"/>
                                      <label [attr.for]="i + '-radio-' + indexAdd"></label>
                                    </div>
                                  </div> -->

                                  <!-- <div class="parte-2" *ngIf="!this.readOnlyState && categorie.division == 1">
                                    <checkbox-radius (checkboxChange)="onCheckboxChange($event)" [checkboxData]="{checked: false, value: {adicional: adicional, categorie: categorie, index: i}}"></checkbox-radius>
                                  </div> -->

                                </div>
                              </div>
                            </ng-container>
                          <!-- </ng-container> -->
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </ng-container>

          <!--Caixa de observação-->
          <div class="box-collapse" *ngIf="!this.readOnlyState && this.company.configs.prodObsActive == 1">
            <div class="box-title">
              <div class="box-title-content">
                <div style="text-align: left;">
                  <span>Observações</span>
                </div>
                <div style="display: flex; align-items: center;">
                </div>
              </div>
            </div>
            <div class="collapse">
              <div style="width: 100%;">
                <div class="collapse-list">
                  <div class="selector" style="padding-bottom: 80px;">
                    <textarea not-emoji class="text-area-observation" placeholder="Ex: Tirar cebola, ovo, etc." [value]="this.textObservation" (input)="capturaTexArea($event)" maxlength="48"></textarea>
                    <div style="display: flex; font-size: 14px; color: #737373;padding-top: 2px;">
                      <span class="d-flex" style="width: 20px; justify-content: flex-end;">{{ this.countText }}</span><span>/48</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="bottom-navegation" *ngIf="!this.readOnlyState">
        <div class="bottom-navegation-box">
          <div class="bottom-counter">
            <div>
              <div class="counter">
                <button class="button-down" #btnNegative [ngClass]="{'activated': this.counter >= 2}">
                  <div class="btn-negative">
                    <div (click)="removeOneInCounter()">-</div>
                  </div>
                </button>
                <div class="counter-div">{{ this.counter }}</div>
                <button class="button-plus" [ngClass]="{'disabled': this.counter >= 10}">
                  <div class="btn-positive">
                    <div (click)="addOneInCounter()">+</div>
                  </div>
                </button>
              </div>
            </div>
            <button class="button-advance" (click)="this.continuarCompra()"[ngClass]="{'disable': this.desabilitButton}">
              <div class="div-button-add">
                <span class="span-1">Adicionar</span>
                <span class="span-2">{{ totalValue | currency:'BRL'}}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  <!--#endregion-->
</div>
