<div class="div-header" #divBar *ngIf="this.requestSuccess" [ngStyle]="{'display': includeDivHeader ? 'block' : 'none'}">
  <div class="justify-content-between align-items-center d-flex content">
      <ng-container *ngIf="this.company != undefined">
        <div class="div-company" *ngIf="!this.buttonClicked">
          <div class="div-company__logo" >
            <div [ngStyle]="{'background-image': 'url('+ this.imgLogo +')'}"></div>
          </div>
          <div style="padding-left: 10px; display: flex; flex-direction: column;">
            <span class="span-company">{{ this.nameFantasyCompany}}</span>
            <ng-container *ngIf="this.typeParam.type == 1">
              <span class="span-identification">MESA {{ this.typeParam.numberParam }}</span>
            </ng-container>
            <ng-container *ngIf="this.typeParam.type == 2">
              <span class="span-identification">COMANDA {{ this.typeParam.numberParam }}</span>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <div [ngStyle]="{'width': this.buttonClicked ? '100%': '0px'}">
        <div class="icon-search-two" #divIcon *ngIf="this.buttonClicked" >
          <i class="fa fa-search" aria-hidden="true" style="padding-right: 10px;" type="button"></i>
        </div>
      </div>
      <div class="d-flex div-button-for-top" style="gap: 13px;">
        <button class="button-for-search" (click)="scrollToTopInputShow();" #buttonSearch style="width: 30px; height: 30px; border: 1px solid #a5a2a2;" *ngIf="!this.buttonClicked">
          <i class="fa fa-search searsh-icon" aria-hidden="true" type="button"></i>
        </button>
        <button class="icon-content" style="border: 1px solid #a5a2a2;" (click)="scrollToTop()" [ngClass]="{'clicked': isClicked}" *ngIf="!this.buttonClicked">
          <i class="fa fa-arrow-up arrow-Up" aria-hidden="true"></i>
        </button>
      </div>
  </div>
</div>
