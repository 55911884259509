import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, ConnectionService } from '../../services/Connection/connection.service';
import { paymentFormCompany } from 'src/app/Interfaces/company';

@Component({
  selector: 'app-payment-form-card',
  templateUrl: './payment-form-card.component.html',
  styleUrls: ['../../../../assets/styles/global/_card.css', './payment-form-card.component.css']
})
export class PaymentFormCardComponent implements OnInit {
  step = false;
  formEmpty!: paymentFormCompany
  @Input() methodReceipt: { checked: boolean, value: number } = {checked: false, value: 0}
  @Input() typeMethodPayment: { checked: boolean, value: number } = {checked: false, value: 0}

  @Output() optionSelected: EventEmitter<{ checked: boolean, value: number, form: paymentFormCompany}> = new EventEmitter<{ checked: boolean, value: number, form: paymentFormCompany}>();

  selectedOption: { checked: boolean, value: number, form: paymentFormCompany} = { checked: false, value: 0, form: this.formEmpty};
  company!: Company;

  constructor(private connection: ConnectionService){}

  ngOnInit(): void {
    this.connection.company$.subscribe((newCompany) =>{
      this.company = newCompany
    })
    if(this.selectedOption.checked == false)
    {
      this.step = true;
    }
  }

  onCheckboxChange(data: { checked: boolean, value: any }, form: paymentFormCompany) {
    this.selectedOption = { 
      checked: data.checked,
      value: data.value,
      form: form
    };

    this.optionSelected.emit({
       checked: data.checked,
       value: data.value,
       form: form
    });
  }

  changeButton(){
    this.selectedOption.checked = false;
    this.selectedOption.value = 0
    this.selectedOption.form = this.formEmpty;
    
    this.optionSelected.emit({
      checked: false,
      value: 0,
      form: this.formEmpty
    });
  }
}
