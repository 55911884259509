import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data, Wellcome } from '../../../Interfaces/produtos';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from '../Cache/cache.service';
import { OrderLocal, OrderUser} from 'src/app/Interfaces/pedido';
import { Authentication } from 'src/app/guard/verification/verification.guard';
import { event } from '../events/events.service';
import { AllOrders, FormPaymentLocal, SingleOrder } from 'src/app/Interfaces/order';
import { environment } from 'src/environments/environment';
import { Fidelity } from 'src/app/Interfaces/fidelity';
import { Address, Cliente, Usuario } from 'src/app/Interfaces/customer';
@Injectable({
  providedIn: 'root'
})

export class ApiService {

  urlTester = '';
  urlAPI = environment.apiUrl;
  urlWebHook = environment.webHookUrl

  constructor(private httpClient: HttpClient, private cacheService: CacheService){}

  //#region Gets

  //Verifica como esta o status do pix

  promotionPaymentForm(order: any): Observable<any>{
    const url = `${this.urlAPI}/orders/promotionalproduct/verify`
    const body = order

    return this.httpClient.post<any>(url, body)
  }


  confirmationPix(id_order: string, id_company: number): Observable<any>{
    const url = `${this.urlAPI}/pix/payment/${id_order}/${id_company}`

    return this.httpClient.get(url);
  }

  startVerification(id_order: string, id_company: number): Observable <any>{
    const url = `${this.urlWebHook}/payment/startVerification/${id_order}/${id_company}`;

    return this.httpClient.get(url);
  }

  getStatusOrder(id_order: string) :Observable <any>{
    const url = `${this.urlAPI}/orders/getStatusOrder/${id_order}`;

    return this.httpClient.get(url);
  }

  //Resgata o endereço do cliente pelo cep
  getCepApi(cep: number):Observable<any>{

    const url = `https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep/${cep}`;

    return this.httpClient.get(url, { headers: { 'x-cpf-usuario': '12363288920' } });
  }

  //Recebe o valor e a distancia da rota do estabelecimento até o cliente
  deliveryPrice(id_company: string, origin: string, destination: string, id_Address: string, valueOrder: number):Observable<any>{

    const encodedOrigin = encodeURIComponent(origin);
    const encodedDestination = encodeURIComponent(destination);

    const url = `${this.urlAPI}/distances/route/${id_company}/${encodedOrigin}/${encodedDestination}/${id_Address}/${valueOrder}`;
    // const url = `http://192.168.100.29:4201/distances/route/${id_company}/${encodedOrigin}/${encodedDestination}/${id_Address}`;

    return this.httpClient.get<any>(url)
  }

  //Resgata as informações do estabelecimento
  getCompany(name_Company: string): Observable<any>{

    const nameCompanyFormated = encodeURIComponent(name_Company);

    let url = (`${this.urlAPI}/company/${nameCompanyFormated}`);

    return this.httpClient.get<any>(url);
  }

  //Verifica se o numero do QrCode é valido
  getAuthentication(number: string, nameCompany: string): Observable<Authentication>{

    // let url = (`http://192.168.100.29:4201/tables/id/${number}/${nameCompany}`)
    let url = (`${this.urlAPI}/tables/id/${number}/${nameCompany}`);

    return this.httpClient.get<Authentication>(url);

  }

  //Resgata um unico pedido pelo seu ID
  getSingleOrder(id_order: number){

    let url = (`${this.urlAPI}/orders/${id_order}`);

    return this.httpClient.get<AllOrders>(url);

  }

  //Resgata todos os pedidos do usuário
  getOrdersUser(id: string, id_empresa: number, quantity: number):Observable<AllOrders>{

    let url = '';
    url = (`${this.urlAPI}/orders/customer/${id}/${id_empresa}/${quantity}`);

    return this.httpClient.get<AllOrders>(url);
  }

  //Resgata o pedido que esta em consumo na mesa ou comanda
  getOrderConsumption(card: number, table: number, id_empresa: number):Observable<SingleOrder>{
    let url = '';

    // url = (`https://api.pedeaqui.app/orders/localOrders/card/${card}/table/${table}/company/${id_empresa}`)
    if(card != 0){
      url = (`${this.urlAPI}/orders/localOrders/card/${card}/table/0/company/${id_empresa}`);
    }
    else{
      url = (`${this.urlAPI}/orders/localOrders/card/${card}/table/${table}/company/${id_empresa}`);
    }


    return this.httpClient.get<SingleOrder>(url);
  }

  //Resgata as informações do usuario pelo seu numero de telefone
  getUsuario(phoneNumber: string):Observable<Usuario>{
    const url = (`${this.urlAPI}/customer/${phoneNumber}`);

    return this.httpClient.get<Usuario>(url);
  }

  //Resgata um produto especifico pelo de ID
  getProduto(id: number, typeParam: number): Observable<Data> {
    let headers;
    if(typeParam == 1 || typeParam == 2){
      headers = new HttpHeaders().set('type', '1')
    }
    else{
      headers = new HttpHeaders().set('type', '2')
    }
    return this.httpClient.get<Data>(`${this.urlAPI}/products/additional/${id}`, { headers });
  }

  //Resgata todas as categorias e produtos do estabelecimento
  getTabela(): Observable<Wellcome> {
    const cacheData = this.cacheService.getItemSession('tabela_cache_key');

    if(cacheData)
    {
      return of(cacheData);
    }
    else{

      return this.httpClient.get<Wellcome>(`${this.urlAPI}/products/company`).pipe(
        tap(data => this.cacheService.setItemSession('tabela_cache_key', data))
      );
    }
  }

  //Resgata as horas do servidor
  getHours():Observable<any>{

    const url = `${this.urlAPI}/company/get/HourServer`;

    return this.httpClient.get<any>(url);

  }

  getObservation(id_company: number):Observable<any>{

    const url = `${this.urlAPI}/company/observations/${id_company}`;

    return this.httpClient.get<any>(url);
  }

  verifiStatusCompany(id_company: number):Observable<any>{
    const url = `${this.urlAPI}/teste/getTeste/${id_company}`;
    return this.httpClient.get<any>(url);
  }

  getFidelity(id_customer: string, id_company: number):Observable<any>{
    
    const url = `${this.urlAPI}/customer/fidelity/${id_customer}/${id_company}`;

    return this.httpClient.get<any>(url);
  }

  //#endregion

  //#region Post

  //Envia o pix com as informações de pagamento
  paymentPix(order: any){
    const url = `${this.urlAPI}/pix/QrCode`;

    const body = {
      referenciaExterna: order.id_order,
      valor: order.orderPrice,
      mensagemPagador: "Pedeaqui.app",
      expiracao: 500,
      id_company: order.id_company
    }

    return this.httpClient.post<any>(url, body);
  }

  //Verifica se o cupom existe e se é valido
  VerifiCoupon(cupom: string, id_company: number, day: number, productsOrder: any, id_customer: string):Observable<any>{
    const url = `${this.urlAPI}/orders/getCoupons/${id_company}/${day}/${cupom}/${id_customer}`;

    const body = {
      products: productsOrder
    }

    return this.httpClient.post<any>(url, body);
  }

  //Registra o endereço do usuario
  postAddress(id_customer: string, address: Address):Observable<any>{

    const url = `${this.urlAPI}/customer/address`;

    return this.httpClient.post<any>(url, {id_customer, address});
  }

  //Envia um evento para API
  events(event: event):Observable<any>{

    let url = (`${this.urlAPI}/orders/new/events`);

    return this.httpClient.post<event>(url, event);
  }

  //Responsavel por enviar o pedido para API
  FazerPedido(Pedido: OrderUser):Observable<any>{
    let url = '';
    url = (`${this.urlAPI}/orders`);

    return this.httpClient.post<any>(url, Pedido);
  }

  //Responsavel por enviar o pedido para API só que pedido em Mesa
  MakeOrderLocal(Pedido: OrderLocal):Observable<any>{

    // let  url = ("http://192.168.100.29:4201/orders/localOrder");
    let  url = (`${this.urlAPI}/orders/localOrder`);
    return this.httpClient.post<any>(url, Pedido);

  }

  //Altera o pedido quando o usuario adiciona mais um produto ao seu consumo na mesa ou comanda
  AlterarPedido(Pedido: OrderUser):Observable<any>{

    let url = `${this.urlAPI}/orders/items`;

    return this.httpClient.post<any>(url, Pedido);
  }

  //Registra o usuario na base
  postUsuario(user: Cliente): Observable<Usuario>{
    const url = (`${this.urlAPI}/customer`);

    const usuario = user;

    return this.httpClient.post<any>(url, usuario);
  }

  requestCoupon(id_customer: string, id_company: number):Observable<any>{
    const url = (`${this.urlAPI}/customer/fidelityCoupon/${id_customer}/${id_company}`);

    return this.httpClient.post<any>(url, {});
  }

  //#endregion

  //#region Paths

  //
  alterationStatusOrder(id_order: string, id_company: number, status: number): Observable<any>{
    const url = `${this.urlAPI}/orders/orderNew/${id_order}/${id_company}/${status}`;

    return this.httpClient.patch(url, {});
  }

  alterationNameCustomer(id_order: number, newName: string, id_company: string):Observable<any>{
    const url = `${this.urlAPI}/orders/customerName/${id_order}/${newName}/${id_company}`;

    return this.httpClient.patch<any>(url, {});
  }

  //Responsavel por editar o endereço do usuario
  pathAddress(id_customer: string, address: Address):Observable<any>{
    const url = `${this.urlAPI}/customer/address`;


    return this.httpClient.patch<any>(url, {id_customer, address});
  }

  //Responsavel por editar o usuario
  patchUser(id_customer: string, name: string, phone: string, birthDate: string):Observable<any>{
    let url = `${this.urlAPI}/customer`;

    return this.httpClient.patch<any>(url, {id_customer, name, phone, birthDate});
  }

  //Altera o numero da mesa
  pathAlterarionTableNumber(idOrder: number, tableNumber: string, id_empresa:string):Observable<any>{
    let url = (`${this.urlAPI}/orders/tableNumber`);

    const alteration = {
      id_order: idOrder,
      tableNumber: tableNumber,
      id_company: id_empresa
    }

    return this.httpClient.patch<any>(url, alteration);
  }

  //Solicita a conta
  pathRequestBill(idOrder: number, formPayment: FormPaymentLocal):Observable<FormPaymentLocal>{
    let url = (`${this.urlAPI}/orders/payment/info/${idOrder}`);

    return this.httpClient.patch<FormPaymentLocal>(url, formPayment);

  }

  //Finaliza o pedido na mesa (Função inativa)
  finalizarPedido(id_Order: number){
    let url = `${this.urlAPI}/orders/status`;

    const alteration = {
      id_order: id_Order,
      orderStatus: 4
    }

    return this.httpClient.patch<any>(url, alteration);

  }

  //Atualiza o preço do produto (Função inativa)
  putProduto(produtoId: number, nome: string, preco: number): Observable<any>{

    const produtoAtualizado = {
      id_produtos: produtoId,
      nome: nome,
      preco: preco
    };

    const url = (`${this.urlAPI}/produtos/`);

    return this.httpClient.patch<any>(url, produtoAtualizado);
  }

  //Deleta o endereço do usuario
  deleteAddress(id_customer: string, id_address: string):Observable<any>{
    const url = `${this.urlAPI}/customer/delete/${id_address}/${id_customer}`;

    return this.httpClient.patch<any>(url, {});
  }

  //#endregion

  //#region Deletes

  //Deleta um produto (Função inativa)
  deleteProduto(idProduto: number): Observable<any> {

    const url = (`${this.urlAPI}p/produtos/`);

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { id_produtos: idProduto }
    };


    return this.httpClient.request<any>('delete', url, httpOptions);
  }
  //#endregion

}
