
<div>
  <!--Side-bar-->
  <app-side-bar *ngIf="showSideBar" [sections]="this.sections" [tabelaFiltrada]="this.tabelaFiltrada" [filter]="this.filter" (showModalCompany)="openModalCompanyEvent($event)"></app-side-bar>

  <!--Top menu Mobile-->
  <div class="top-menu" id="top-menu" [ngClass]="{'hidden': this.alterationBg,'loaded': this.backgroundLoad}"  [ngStyle]="{'background-image': this.backgroundLoad ? 'url(' + this.imgBackground + ')' : ''}" #topMenu>
    <img [src]="this.imgBackground" (load)="loadBackground()" style="display: none;" />
    <div class="gradient" *ngIf="this.backgroundLoad"></div>
    <div class="menu-container">
        <div class="menu-search row-center">
          <div class="d-flex">
            <div class="company-info-mobile">
              <img class="img-company-image" [src]="imgLogo" #imgCompany>
            </div>
            <div class="button-light-theme" (click)="this.collapseBox = !this.collapseBox" >
              <i class="fa-solid fa-bars" style="font-size: 17px; color: #b9b9b9; font-weight: 100;"></i>
            </div>
            <div class="collapse-box" *ngIf="this.collapseBox">
              <ul>
                <!-- <li (click)="this.addclass(); this.collapseBox = !this.collapseBox">
                  <i class="fa-solid fa-toggle-on fa-rotate-180" style="color: #afb0b1; font-size: 30px;" *ngIf="!this.toggleTheme"></i>
                  <i class="fa-solid fa-toggle-on" style="color: #ff79ff !important; font-size: 30px;" *ngIf="this.toggleTheme"></i>
                  Tema escuro
                </li> -->
                <li (click)="share()">
                  <!-- <img src="assets/icons/share.png" style="width: 20px;" *ngIf="!this.toggleTheme"/>
                  <img src="assets/icons/share dark.png" style="width: 20px;" *ngIf="this.toggleTheme" /> -->
                  <i class="fa-solid fa-share-from-square" style="font-size: 18px; color: var(--primary-color)"></i>
                  Compartilhar
                </li>
                <li (click)="openModalCompany()"><i class="fa-solid fa-circle-exclamation" style="font-size: 20px; color: var(--primary-color);"></i>Informações</li>
              </ul>
            </div>
          </div>
          <div class="info-company" [ngClass]="{'close-infos': this.typeParam.type != 0 && this.typeParam.type != 3 && this.readOnlyState}">
            <span>{{ this.nameEmpresa }}</span>
            <ng-container *ngIf="this.company && !this.readOnlyState">
              <ng-container *ngIf="this.typeParam.type == 0 || this.typeParam.type == 3">
                <span class="namestatusCompany" [ngStyle]="{'color': this.statusCompany ? '#3dab3d' : '#d70000'}">{{ this.nameStatusCompany }}</span>
                <!-- <span class="close-open-span" style="font-weight: 100;" *ngIf="this.company.company.status == 1">Fecha às {{ this.timeClose }}h</span>
                <span class="close-open-span" style="font-weight: 100;" *ngIf="this.company.company.status == 0">Abre às {{ this.timeOpen }}h</span> -->
              </ng-container>
            </ng-container>

            <!-- <span style="font-size: 13px; color: #c7c3c3;">Ver mais...</span> -->
          </div>
          <div class="search-bar row-center"(click)="this.collapseBox = false" >
              <div class="search-bar-input" >
                <div class="div-input" #divInput *ngIf="this.showInput">
                  <div class="icon-search"  #divIcon>
                      <i class="fa fa-search" aria-hidden="true" type="button"></i>
                  </div>
                  <input class="mobile" type="text" placeholder="Esta com fome? Pedeaqu!"  [value]="this.filter" (input)="updatefilter($event)" (blur)="isFilterEmpty() ? isElementFocused() : null" #input>
                  <div style="cursor: pointer; position: absolute; right: 29px; top: 6px;" *ngIf="this.filter.length > 0" (click)="clearFilter()">
                    <i class="fa-solid fa-x cancel-input"></i>
                  </div>
                  <!-- <input class="desktop" type="text" placeholder="Esta com fome? Pedeaqu!" #inputDesktop> -->
                </div>
                <div class="button-for-search" #buttonSearch (click)="expanderInput()" *ngIf="!this.showInput">
                  <i class="fa fa-search" style="color: #b9b9b9;" aria-hidden="true" type="button"></i>
                </div>
              </div>
          </div>
        </div>
        <div>

        </div>
        <div class="menu-top-buttons">
            <a class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i class="fa-solid fa-cart-shopping" aria-hidden="true" ></i>Carrinho</a>
        </div>
    </div>
  </div>
  <div>
    
  </div>

  <!--Top menu Desktop-->
  <div class="top-menu" id="top-menu" [ngClass]="{'hidden': !this.alterationBg}">
    <div class="menu-container">
        <div class="menu-search row-center">
            <div class="search-bar row-center">
                <div class="search-bar-input" >
                  <div class="div-input" #divInput>
                    <div class="icon-search-desktop" #divIcon>
                        <i class="fa fa-search" aria-hidden="true" style="padding-right: 10px; color: #652969;" type="button"></i>
                    </div>
                    <input class="desktop" type="text" placeholder="Esta com fome? Pedeaqu!"   [value]="this.filter" (input)="updatefilter($event)" #inputDesktop>
                    <div style="cursor: pointer; position: relative; right: 29px; top: 5px;" *ngIf="this.filter.length > 0" (click)="clearFilter()">
                      <i class="fa-solid fa-x" style=" font-size: 12px; color: #652969;"></i>
                    </div>
                  </div>

                </div>
            </div>
        </div>
        <ng-container *ngIf="this.company">
          <div class="menu-top-buttons" *ngIf="!this.readOnlyState">
            <a style="height: 50px;" [routerLink]="['/', nameCompany, 'carrinho']"><i class="fa-solid fa-cart-shopping" aria-hidden="true" (click)="resgataPosition()"></i><span>Carrinho</span><span *ngIf="this.counterCart > 0" class="icon-quantity">{{ counterCart }}</span></a>
            <a [routerLink]="['/', this.nameCompany, 'consumption']" *ngIf="this.verification"><i class="fa-solid fa-book" style="text-align: center;"></i><span>Consumo</span></a>
            <a [routerLink]="['/', this.nameCompany, 'pedidos']" *ngIf="this.userConfirmation && !this.verification"><i class="fa-solid fa-book" style="text-align: center;"></i><span>Pedidos</span></a>
            <a [routerLink]="['/', this.nameCompany, 'user']" *ngIf="!this.verification"><i class="fa-solid fa-user" style="text-align: center;"></i><span>Perfil</span></a>
            <div class="button-light-theme" style="top: 22px; margin-right: 26.5px; " (click)="this.collapseBox = !this.collapseBox" >
              <i class="fa-solid fa-bars" style="font-size: 20px; color: #808080; font-weight: 100;"></i>
            </div>
            <div class="collapse-box" style="top: 51px; right: 25px; width: 190px;" *ngIf="this.collapseBox">
              <ul>
                <!-- <li (click)="this.addclass(); this.collapseBox = !this.collapseBox">
                  <i class="fa-solid fa-toggle-on fa-rotate-180" style="color: #afb0b1; font-size: 30px;" *ngIf="!this.toggleTheme"></i>
                  <i class="fa-solid fa-toggle-on" style="color: #ff79ff !important; font-size: 30px;" *ngIf="this.toggleTheme"></i>
                  Tema escuro
                </li> -->
                <li (click)="share()">
                  <i class="fa-solid fa-share-from-square" style="font-size: 18px; color: var(--primary-color)"></i>
                  Compartilhar
                </li>
                <li (click)="openModalCompany()"><i class="fa-solid fa-circle-exclamation" style="font-size: 20px; color: var(--primary-color);"></i>Informações</li>
              </ul>
            </div>
          </div>
        </ng-container>

    </div>
  </div>


  <!--Div que é mostrada no modo tablet/celular para quando a pessoa descer to topo da pagina-->
  <app-top-header [requestSuccess]="requestSuccess" (showInput)="this.showInput = $event" (firstClick)="this.firstClick = $event"></app-top-header>

  <!--Menu inteiro-->
  <div #menu>
    <div class="page-wrapper desktop" [ngClass]="alterationpadding ? 'little' : 'large'">
      <div class="menu">
        <app-top-bar [sections]="this.sections" [tabela]="this.tabela" [verifiScrolling]="this.verifiScrolling" [tabelaFiltrada]="this.tabelaFiltrada"></app-top-bar>

        <!--Lista de Categorias, para listar os produtos-->
        <div class="lista-category">
          <!--Primeira Lista-->
          <ng-container *ngFor="let category of tabela; let last = last; let indexSection = index" >
            <ng-container *ngIf=" this.filter == '' ">
              <section class="painel" [id]="category.categoryName" [ngStyle]="{'margin-bottom.px': last ? 0 : 40}" #sections>
                <div class="title-painel">
                  <h2 style="margin-bottom: 0px;">{{ category.categoryName }}</h2>
                </div>
                <div class="body-painel">
                  <div class="products">
                    <ng-container *ngFor="let product of category.products; let index = index">
                      <ng-container *ngIf="!filter || product.name.includes(filter)">
                        <div class="product-card">
                          <a (click)="capturaId(product.id_product, indexSection)">
                            <div class="body-card">
                              <div class="d-flex">
                                <div class="image-card" [ngClass]="{'not-animation': this.imgsLoadead[index]}">
                                  <img [src]="product.image_prod" (load)="this.loadImgComplete($event, index)" style="width: 100px; height: 100px;">
                                </div>
                                <!-- <div class="image-card">
                                  <picture>
                                    <source [srcset]="product.image_prod" (load)="this.teste()">
                                    <img [src]="product.image_prod" loading="lazy"/>
                                  </picture>
                                </div> -->
                              </div>
                              <div class="info-card">
                                <div class="description-card">
                                  <div class="name-card" [textContent]="product.name"></div>
                                  <div class="detail-card" [textContent]="product.description"></div>
                                </div>
                                <ng-container *ngIf="product.price != 0">
                                  <ng-container *ngIf="product.promotion == 1">
                                    <div class="box-card-promotion">
                                      <span style="width: 100%;" class="value-card__Promotion">
                                        {{ product.realPrice | currency:'BRL' }}
                                      </span>
                                      <span>
                                        {{ product.price | currency:'BRL' }}
                                      </span>
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="product.promotion == 0">
                                    <span style="width: 100%;" class="value-card__value">
                                      {{ product.price | currency:'BRL' }}
                                    </span>
                                  </ng-container>
                                </ng-container>
                                <ng-container *ngIf="product.price == 0">
                                  <ng-container *ngIf="product.pizza == 1">
                                    <ng-container *ngIf="product.minPrice != '0' ">
                                      <div style="display: flex; width: 100%; justify-content: flex-end; gap: 10px; align-items: center;">
                                        <span class="span_card__value">A partir de</span>
                                        <span class="value-card__value">
                                          {{ product.minPrice | currency:'BRL' }}
                                        </span>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </ng-container>
                              </div>
                              <ng-container *ngIf="product.price == 0">
                                <ng-container *ngIf="product.pizza == 0">
                                  <i class="fa fa-chevron-right" style="color: #652969;"></i>
                                </ng-container>
                              </ng-container>
                            </div>
                          </a>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </section>
            </ng-container>
          </ng-container>


          <!--Se filtro for diferente de vazio, executara esse for-->
          <ng-container *ngFor="let category of tabelaFiltrada; let last = last; let indexSection = index">
            <ng-container *ngIf=" this.filter != '' ">
              <section class="painel" [id]="category.categoryName" [ngStyle]="{'margin-bottom.px': last ? 0 : 23}" #sections>
                <div class="title-painel">
                  <h2 style="margin-bottom: 0px;">{{ category.categoryName }}</h2>
                </div>
                <div class="body-painel">
                  <div class="products">
                    <ng-container *ngFor="let product of category.products; let index = index">
                      <div class="product-card" *ngIf="!filter || product.name.toLowerCase().includes(filter.toLowerCase())">
                        <a (click)="capturaId(product.id_product, indexSection)">
                          <div class="body-card">
                            <div class="d-flex">
                              <div class="image-card not-animation">
                                <img [src]="product.image_prod" style="width: 100px; height: 100px; opacity: 1;">
                              </div>
                            </div>
                            <div class="info-card">
                              <div class="description-card">
                                <div class="name-card" [textContent]="product.name"></div>
                                <div class="detail-card" [textContent]="product.description"></div>
                              </div>
                              <ng-container *ngIf="product.price != 0">
                                <ng-container *ngIf="product.promotion == 1">
                                  <div class="box-card-promotion">
                                    <span style="width: 100%;" class="value-card__Promotion">
                                      {{ product.realPrice | currency:'BRL' }}
                                    </span>
                                    <span>
                                      {{ product.price | currency:'BRL' }}
                                    </span>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="product.promotion == 0">
                                  <span style="width: 100%;" class="value-card__value">
                                    {{ product.price | currency:'BRL' }}
                                  </span>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="product.price == 0">
                                <ng-container *ngIf="product.pizza == 1">
                                  <ng-container *ngIf="product.minPrice != '0' ">
                                    <div style="display: flex; width: 100%; justify-content: flex-end; gap: 10px; align-items: center;">
                                      <span style="font-size: 10px; color: #652969; font-weight: 600;">A partir de</span>
                                      <span style="color: #652969; font-size: 16px; font-weight: 600; text-align: right;" class="value-card__value">
                                        {{ product.minPrice | currency:'BRL' }}
                                      </span>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </div>
                            <ng-container *ngIf="product.price == 0">
                              <ng-container *ngIf="product.pizza == 0">
                                <i class="fa fa-chevron-right" style="color: #652969;"></i>
                              </ng-container>
                            </ng-container>
                          </div>
                        </a>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </section>
            </ng-container>
          </ng-container>

          <!--Se nenhum produto for encontrado-->
          <ng-container *ngIf="emptyProduct">
            <div class="message-not-found">
              <span style="color: gray">Nenhum Produto encontrado</span>
            </div>
          </ng-container>

          <div class="WariningPowered">
            <!-- <div style="position: relative;top: 10px;">
              <img src="assets/logo/logo-qrcode-pedeaqui.png" style="width: 100px;"/>
            </div> -->
            <div style="position: relative; top: 17px; left: 2px;">
              <span style=" color: #652969; font-size: 13px;">from</span>
            </div>
            <!-- <span style="font-weight: 600;color: #652969; font-size: 16px;">&copy; Powered by <img src="assets/logo/logo-name-pedeaqui.png" style="width: 105px;"/></span> -->
            <div>
              <img src="assets/logo/logo-qrcode-pedeaqui.png" style="width: 32px;"/><img src="assets/logo/logo-name-pedeaqui.png" style="width: 100px;"/>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<app-info-company [openModalTwo]="this.openModalTwo" (closeModal)="this.openModalTwo = false; this.enableScroll()"></app-info-company>
<!--Bottom Menu-->
<app-bottom-menu></app-bottom-menu>
